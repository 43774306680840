/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql, withPrefix } from "gatsby"

function SEO({ description, lang, meta, title, image, path }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            keywords
            image
            logo
            siteUrl
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  const url = path ? `${site.siteMetadata.siteUrl}${path}` : site.siteMetadata.siteUrl
  const imageUrl = image ? `${site.siteMetadata.siteUrl}${path}` : `${site.siteMetadata.siteUrl}${site.siteMetadata.logo}`

  title = title || site.siteMetadata.title;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      link={
        [
          {
            rel: 'canonical',
            href: url,
          }
        ]
      }
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          charSet: 'utf-8'
        },
        {
          itemprop: 'name',
          content: title
        },
        {
          itemprop: 'description',
          content: metaDescription
        },
        {
          itemprop: 'image',
          content: imageUrl
        },
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale = 1.0, maximum-scale=1.0, user-scalable=no'
        },
        {
          name: 'keywords',
          content: site.siteMetadata.keywords,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: 'og:url',
          content: url,
        },
        {
          property: 'og:image',
          content: site.siteMetadata.image,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:site`,
          content: url,
        },
        {
          name: `twitter:image`,
          content: site.siteMetadata.image,
        },
      ].concat(meta)}
    >
      <script src={`${withPrefix('/')}script/widget.js`}/>
      <script type='text/javascript' src='https://ind-widget.freshworks.com/widgets/82000001893.js' async defer></script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  path: PropTypes.string,
}

export default SEO
