import React, { useEffect } from "react"
import { store } from "react-notifications-component"
import FileSaver from 'file-saver'
import useSiteMetadata from "./SiteMetaData"

const Download = () => {
  const {link} = useSiteMetadata()

  useEffect(()=>download(link), [])

  return(
    <div className="section">
      <div className="container has-text-centered">

        <div className="columns">

          <div className="column">
            <div
              className="title is-padded-bottom has-text-weight-normal has-text-white is-size-2-widescreen is-size-3-desktop is-size-4-tablet is-size-5-mobile">
              Get the <a href='#' onClick={download} className="is-link has-text-danger">Beatshine Extension</a>. With different speed levels and random pauses after some time making it impossible to detect. <br /><br />
{/*              Your download will begin in a moment. Else you can click on
              <a href='#' onClick={download} className="is-link has-text-danger">&nbsp;this link&nbsp;</a>
              to download.*/}
		If the chrome store isn't opened click on the above button to  open it.
            </div>
            <div className="horizantal-line is-padded-bottom"/>
            <div
              className="title is-padded-bottom has-text-white is-size-1-widescreen is-size-2-desktop is-size-3-tablet is-size-4-mobile"
              id="how-to-section">
              How to Install
            </div>
            <div className="iframe-container">
              <iframe
                width="560px"
                height="315px"
                title="Video Tutorial | Beatshine"
                src="https://youtube.com/embed/3eYi_wtQSMA"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />+
            </div>
          </div>

        </div>

      </div>
    </div>
  )
}

export const download = (link) => {

  if (typeof window !== "undefined") {
    if (window.fbq != null) {
      window.fbq('trackCustom', 'Install Beatshine Extension');
    }
  }

  window.open(link, "_blank")
/*  FileSaver.saveAs(link, "beatshine.zip")
  store.addNotification({
    title: '',
    message: 'Your Download will Start Shortly',
    type: 'success',
    insert: 'top',
    container: 'top-right',
    animationIn: ["animated", "fadeIn"],
    animationOut: ['animated', "faeOut"],
    dismiss: {
      duration: 5000,
      onScreen: false
    }
  })*/
}

export default Download
