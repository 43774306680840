import React from "react"
import './all.sass'
import useSiteMetadata from "./SiteMetaData"
import NavigationBar from "./Navbar"
import Footer from "./Footer"

const Layout = ({ children }) => {
  const {title, description } = useSiteMetadata()

  return (
    <>
      <NavigationBar />
      <main>{children}</main>
      <Footer />
    </>
  )
}

export default Layout
