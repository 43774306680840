import CreateTicket from "./CreateTicket";
import { store } from 'react-notifications-component'

const success = () => {
  store.addNotification({
    title: '',
    message: 'Your Ticket has been submitted.',
    type: 'success',
    insert: 'top',
    container: 'top-right',
    animationIn: ["animated", "fadeIn"],
    animationOut: ['animated', "faeOut"],
    dismiss: {
      duration: 5000,
      onScreen: false
    }
  })
};

const error = () => {
  store.addNotification({
    title: '',
    message: 'Something Went Wrong',
    type: 'danger',
    insert: 'top',
    container: 'top-right',
    animationIn: ["animated", "fadeIn"],
    animationOut: ['animated', "faeOut"],
    dismiss: {
      duration: 5000,
      onScreen: false
    }
  })
};

function TicketFunction(email, name, subject, description, product) {
  CreateTicket(email, name, subject, description, product)
    .then(r => {
      if(r.id) {
        success()
      } else {
        error()
      }
    })
}

export default TicketFunction